import { Component, Input, OnInit } from '@angular/core';
import { Toast } from '@capacitor/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { LoanapproveService } from 'src/app/services/loanapprove.service';

@Component({
  selector: 'app-viewloandetails',
  templateUrl: './viewloandetails.page.html',
  styleUrls: ['./viewloandetails.page.scss'],
})
export class ViewloandetailsPage implements OnInit {
  
  @Input() data: any;
  loading: HTMLIonLoadingElement;
  LoanDetails:any;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private loanApproveService: LoanapproveService,
  ) { }

  ngOnInit() {
    this.LoanDetails=this.data;
  }
  
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
    // history.back();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  async presentLoading(msg: any = 'Please Wait...') {
    this.loading = await this.loadingController.create({
      message: msg
    });
    return await this.loading.present();
  }

  async showToast(msg) {
    await Toast.show({
      text: msg
    });
  }
}
