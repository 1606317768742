import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsettingsService {

  constructor(public httpService: HttpService) { }

  insert(postData: any): Observable<any> {
    return this.httpService.post('contactsettings/insert.php', postData);
  }

  get(postData: any): Observable<any> {
    return this.httpService.get('contactsettings/read_one.php?Id='+ postData);
  }
  
  update(postData: any): Observable<any> {
    return this.httpService.post('contactsettings/update.php', postData);
  }
  
}
