import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FixeddepositService {

  constructor(public httpService: HttpService) { }

  insert(postData: any): Observable<any> {
    return this.httpService.post('fixeddeposit/insert.php', postData);
  }

  getAll(): Observable<any> {
    return this.httpService.get('fixeddeposit/read.php');
  }

  get(postData: any): Observable<any> {
    return this.httpService.get('fixeddeposit/read_one.php?Id='+postData);
  }

  getActiveFD(): Observable<any> {
    return this.httpService.get('fixeddeposit/read_active.php');
  }

  getDeactiveFD(): Observable<any> {
    return this.httpService.get('fixeddeposit/read_deactive.php');
  }

  searchFD(): Observable<any> {
    return this.httpService.get('fixeddeposit/searchfd.php');
  }

}
